// import React from 'react';
// import { Link, useLocation } from 'react-router-dom';

// const headerStyle = {
//   display: 'flex',
//   justifyContent: 'space-between',
//   padding: '1rem',
//   backgroundColor: '#fff', // Changed to white
//   color: '#475974', // Dark blue for text
//   alignItems: 'center',
//   position: 'relative',
//   zIndex: 1 // Ensure the header is above other content
// };

// const logoStyle = {
//   height: '3rem',
//   marginLeft: '2rem',
//   marginTop: '1rem',
// };

// const navStyle = {
//   display: 'flex',
//   listStyle: 'none',
//   margin: 0,
//   padding: 0
// };

// const navItemStyle = {
//   margin: '0 1rem',
//   fontWeight: 'bold', // Change text weight
//   fontSize: '1.5rem', // Change text size
//   position: 'relative'
// };

// const linkStyle = {
//   color: '#475974', // Dark blue for links
//   textDecoration: 'none',
//   fontFamily: 'Arial, sans-serif', // Change font family
//   fontWeight: '600', // Change font weight
//   letterSpacing: '0.05rem', // Add letter spacing
//   position: 'relative',
//   display: 'inline-block',
//   marginRight: '3rem',
// };

// const linkHoverStyle = {
//   color: '#475974',
//   textDecoration: 'none',
//   position: 'relative',
//   transition: 'color 0.3s ease-in-out'
// };

// const linkHoverAfterStyle = {
//   content: '""',
//   position: 'absolute',
//   width: '0',
//   height: '2px',
//   display: 'block',
//   marginTop: '5px',
//   right: '0',
//   background: '#475974', // Dark blue underline
//   transition: 'width 0.3s ease-in-out'
// };

// const linkHoverAfterHoverStyle = {
//   width: '100%',
//   left: '0',
//   right: 'auto'
// };

// const Header = () => {
//   const location = useLocation();

//   return (
//     <header style={headerStyle}>
//       <div className="logo">
//         <img 
//           src={`${process.env.PUBLIC_URL}/images/logo.png`} 
//           alt="C-PRO Logo" 
//           style={logoStyle} 
//         />
//       </div>
//       <ul style={navStyle}>
//         <li style={navItemStyle}>
//           <Link 
//             to="/" 
//             style={linkStyle} 
//             className={location.pathname === '/' ? 'selected' : ''}
//           >
//             <span style={linkHoverStyle}>
//               Home
//               <span style={{ ...linkHoverAfterStyle, ...(location.pathname === '/' ? linkHoverAfterHoverStyle : {}) }}></span>
//             </span>
//           </Link>
//         </li>
//         <li style={navItemStyle}>
//           <Link 
//             to="/contact" 
//             style={linkStyle} 
//             className={location.pathname === '/contact' ? 'selected' : ''}
//           >
//             <span style={linkHoverStyle}>
//               Contact
//               <span style={{ ...linkHoverAfterStyle, ...(location.pathname === '/contact' ? linkHoverAfterHoverStyle : {}) }}></span>
//             </span>
//           </Link>
//         </li>
//       </ul>
//     </header>
//   );
// };

// export default Header;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css'; // Import the CSS file

const Header = () => {
  const location = useLocation();

  return (
    <header className="header">
      <div className="logo">
        <img 
          src={`${process.env.PUBLIC_URL}/images/logo.png`} 
          alt="C-PRO Logo" 
          className="logo-img" 
        />
      </div>
      <ul className="nav">
        <li className="nav-item">
          <Link 
            to="/" 
            className={`nav-link ${location.pathname === '/' ? 'selected' : ''}`}
          >
            <span className="nav-link-text">
              Home
              <span className="nav-link-underline"></span>
            </span>
          </Link>
        </li>
        <li className="nav-item">
          <Link 
            to="/contact" 
            className={`nav-link ${location.pathname === '/contact' ? 'selected' : ''}`}
          >
            <span className="nav-link-text">
              Contact
              <span className="nav-link-underline"></span>
            </span>
          </Link>
        </li>
      </ul>
    </header>
  );
};

export default Header;




