// import React, { useState } from 'react';
// import emailjs from 'emailjs-com';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// const contactStyle = {
//   textAlign: 'center',
//   padding: '2rem'
// };

// const formContainerStyle = {
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'flex-start',
//   width: '80%',
//   maxWidth: '1200px',
//   margin: '0 auto'
// };

// const formStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   width: '60%', // Adjust the width to fill the form
//   maxWidth: '700px', // Set a maximum width for the form
//   marginLeft: '-6rem'
// };

// const inputStyle = {
//   margin: '0.5rem 0',
//   padding: '0.5rem',
//   width: '100%',
//   borderRadius: '5px',
//   border: '1px solid #ddd',
//   fontStyle: 'italic',
// };

// const textareaStyle = {
//   ...inputStyle,
//   height: '100px'
// };

// const buttonStyle = {
//   padding: '0.75rem 1.5rem',
//   backgroundColor: '#475974', // Dark blue button
//   color: '#fff',
//   border: 'none',
//   borderRadius: '5px',
//   cursor: 'pointer',
//   marginTop: '1rem',
//   fontSize: '1.2rem', // Larger text
//   fontWeight: 'bold', // Bold text
// };

// const logCallStyle = {
//   width: '35%', // Adjust the width to your needs
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   marginRight: '8rem',
//   border: '1px solid #ddd',
//   borderRadius: '5px',
//   height: '14rem',
// };

// const mapStyle = {
//   width: '80%',
//   height: '400px',
//   borderRadius: '5px',
//   margin: '2rem auto'
// };

// const Contact = () => {
//   const [formData, setFormData] = useState({ name: '', email: '', message: '' });
//   const [startDate, setStartDate] = useState(new Date());
//   const [startTime, setStartTime] = useState(new Date());

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const meetingDetails = {
//       ...formData,
//       date: startDate.toLocaleDateString(),
//       time: startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
//     };
//     emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', meetingDetails, 'YOUR_USER_ID')
//       .then((response) => {
//         alert('Message sent successfully!');
//       })
//       .catch((error) => {
//         alert('Failed to send message.');
//       });
//   };

//   return (
//     <div style={contactStyle}>
//       <h2 style={{ color: '#475974', fontStyle: 'italic' }}>Contact Us Today</h2>
//       <div style={formContainerStyle}>
//         <form onSubmit={handleSubmit} style={formStyle}>
//           <input 
//             type="text" 
//             name="name" 
//             placeholder="Enter your name" 
//             onChange={handleChange} 
//             style={inputStyle} 
//           />
//           <input 
//             type="email" 
//             name="email" 
//             placeholder="Enter your email" 
//             onChange={handleChange} 
//             style={inputStyle} 
//           />
//           <textarea 
//             name="message" 
//             placeholder="Message" 
//             onChange={handleChange} 
//             style={textareaStyle} 
//           ></textarea>
//           <button type="submit" style={buttonStyle}>Send</button>
//         </form>
//         <div style={logCallStyle}>
//           <h3 style={{ color: '#475974' }}>Log a Call</h3>
//           <div>
//             <label style={{ color: '#7295c7', fontStyle: 'italic', fontWeight: 'bold' }}>Select Date: </label>
//             <DatePicker
//               selected={startDate}
//               onChange={date => setStartDate(date)}
//               dateFormat="MMMM d, yyyy"
//               style={inputStyle}
//             />
//           </div>
//           <div>
//             <label style={{ color: '#7295c7', fontStyle: 'italic', fontWeight: 'bold' }}>Select Time: </label>
//             <DatePicker
//               selected={startTime}
//               onChange={time => setStartTime(time)}
//               showTimeSelect
//               showTimeSelectOnly
//               timeIntervals={15}
//               timeCaption="Time"
//               dateFormat="h:mm aa"
//               style={inputStyle}
//             />
//           </div>
//           <p style={{ color: '#7295c7', fontStyle: 'italic' }}>If you would like to set up a virtual call, please select a date and time that you would like to meet!</p>
//         </div>
//       </div>
//       <div style={mapStyle}>
//         <iframe
//           title="C-PRO Office Location"
//           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.104188175372!2d28.368157075663536!3d-25.767121977348477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e955ebde14e4fc3%3A0x97739812054bfcde!2s221%20Gleneagles%20Dr%2C%20Silver%20Lakes%20Golf%20Estate%2C%200081!5e0!3m2!1sen!2sza!4v1717845308973!5m2!1sen!2sza"
//           width="100%"
//           height="100%"
//           style={{ border: 0 }}
//           allowFullScreen=""
//           loading="lazy"
//         ></iframe>
//       </div>
//     </div>
//   );
// };

// export default Contact;

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Contact.css'; // Import the CSS file

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
      date: startDate.toLocaleDateString(),
      time: startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };

    emailjs.send('service_5pdp04p', 'template_ky8boy1', templateParams, '6yGynKxMtxxVhwlAh')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log('FAILED...', error);
        alert('Failed to send message.');
      });
  };

  return (
    <div className="contact">
      <h2 className="contact-title">Contact Us Today</h2>
      <div className="form-container">
        <form 
          onSubmit={handleSubmit} 
          className="contact-form"
        >
          <input 
            type="text" 
            name="name" 
            placeholder="Enter your name" 
            onChange={handleChange} 
            className="contact-input"
          />
          <input 
            type="email" 
            name="email" 
            placeholder="Enter your email" 
            onChange={handleChange} 
            className="contact-input"
          />
          <textarea 
            name="message" 
            placeholder="Message" 
            onChange={handleChange} 
            className="contact-textarea"
          ></textarea>
          <button type="submit" className="contact-button">Send</button>
        </form>
        <div className="log-call">
          <h3 className="log-call-title">Log a Call</h3>
          <div>
            <label className="log-call-label">Select Date: </label>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              dateFormat="MMMM d, yyyy"
              className="date-picker"
            />
          </div>
          <div>
            <label className="log-call-label">Select Time: </label>
            <DatePicker
              selected={startTime}
              onChange={time => setStartTime(time)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              className="date-picker"
            />
          </div>
          <p className="log-call-text">If you would like to set up a virtual call, please select a date and time that you would like to meet!</p>
        </div>
      </div>
      <div className="map">
        <iframe
          title="C-PRO Office Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.104188175372!2d28.368157075663536!3d-25.767121977348477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e955ebde14e4fc3%3A0x97739812054bfcde!2s221%20Gleneagles%20Dr%2C%20Silver%20Lakes%20Golf%20Estate%2C%200081!5e0!3m2!1sen!2sza!4v1717845308973!5m2!1sen!2sza"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;



