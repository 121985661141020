// import React, { useEffect, useState } from 'react';

// const homeStyle = {
//   textAlign: 'center',
//   padding: '2rem'
// };

// const imageContainerStyle = {
//   position: 'relative',
//   width: '100%',
//   height: '820px', // Set a fixed height for the image
//   overflow: 'hidden',
//   borderRadius: '2rem',
// };

// const imageStyle = {
//   width: '100%',
//   height: '100%',
//   objectFit: 'cover', // Ensure the image covers the set height
//   display: 'block'
// };

// const serviceImageStyle = {
//   width: '100%',
//   height: '150px', // Adjust this value as necessary
//   objectFit: 'cover',
//   borderRadius: '5px'
// };

// const servicesStyle = {
//   display: 'flex',
//   justifyContent: 'space-around',
//   padding: '2rem',
//   flexWrap: 'wrap'
// };

// const serviceStyle = {
//   width: '30%',
//   border: '1px solid #ddd',
//   borderRadius: '5px',
//   padding: '1rem',
//   margin: '1rem 0',
//   backgroundColor: '#f0f0f0' // Light gray background
// };

// const serviceTextStyle = {
//   color: '#475974', // Change this to your preferred text color
//   fontSize: '1.2rem', // Adjust text size as needed
//   lineHeight: '1.5', // Adjust line height for readability
//   fontStyle: 'italic',
// };

// const portfolioStyle = {
//   textAlign: 'center',
//   padding: '2rem'
// };

// const categoriesStyle = {
//   display: 'flex',
//   justifyContent: 'center',
//   margin: '1rem 0',
//   flexWrap: 'wrap'
// };

// const projectsStyle = {
//   display: 'flex',
//   justifyContent: 'space-around',
//   flexWrap: 'wrap'
// };

// const projectImageStyle = {
//   width: '30%',
//   margin: '1rem'
// };

// const buttonStyle = {
//   backgroundColor: '#7295c7', // Primary light blue
//   color: '#fff',
//   border: 'none',
//   padding: '0.5rem 1rem',
//   margin: '0.5rem',
//   cursor: 'pointer',
//   borderRadius: '5px',
//   transition: 'background-color 0.3s, background-image 0.3s',
//   backgroundImage: 'linear-gradient(to right, #7295c7, #475974)', // Subtle gradient
//   fontSize: '1.5rem',
// };

// const buttonHoverStyle = {
//   backgroundImage: 'linear-gradient(to right, #475974, #7295c7)' // Hover gradient
// };

// const selectedButtonStyle = {
//   backgroundColor: '#475974', // Dark blue for selected button
//   color: '#fff'
// };

// const portfolioImages = {
//   Retail: [
//     '/images/retail/retail2.jpg',
//     '/images/retail/retail6.jpg',
//     '/images/retail/retail1.jpg',
//     '/images/retail/retail7.jpg',
//   ],
//   Hospitality: [
//     '/images/leisure_gaming/leisure6.jpg',
//     '/images/leisure_gaming/leisure7.jpg',
//     '/images/leisure_gaming/leisure3.jpg',
//     '/images/leisure_gaming/leisure5.jpg',
//   ],
//   Commercial: [
//     '/images/commercial/commercial2.jpg',
//     '/images/commercial/commercial7.jpg',
//     '/images/commercial/commercial12.jpeg',
//     '/images/commercial/commercial5.jpg'
//   ],
//   Industrial: [
//     '/images/industrial/industrial1.jpeg',
//     '/images/industrial/industrial5.jpeg',
//     '/images/industrial/industrial8.jpeg',
//     '/images/industrial/industrial3.jpeg',
//   ],
//   Healthcare: [
//     '/images/healthcare/healthcare1.jpg',
//     '/images/healthcare/healthcare2.jpg',
//     '/images/healthcare/healthcare3.jpg',
//     '/images/healthcare/healthcare4.jpg',
//   ]
// };

// const Home = () => {
//   const [selectedCategory, setSelectedCategory] = useState('Retail');

//   const handleCategoryClick = (category) => {
//     setSelectedCategory(category);
//   };

//   useEffect(() => {
//     const styleSheet = document.styleSheets[0];
//     const keyframes = `
//       @keyframes fadeIn {
//         from {
//           opacity: 0;
//         }
//         to {
//           opacity: 1;
//         }
//       }
//     `;
//     styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
//   }, []);

//   return (
//     <div style={homeStyle}>
//       <div style={imageContainerStyle}>
//         <img src="/images/cranes.jpg" alt="Construction Crane" style={imageStyle} />
//       </div>
//       <h1 style={{ color: '#475974' }}>What We Do</h1>
//       <p style={{ color: '#475974', fontSize: '1rem', fontWeight: 'normal', maxWidth: '800px', margin: '0 auto', lineHeight: '1.5', fontStyle: 'italic' }}>
//         PROVEN CONSTRUCTION PROJECT MANAGERS, DELIVERING A PROFESSIONAL SERVICE BASED ON TRUST, INTEGRITY, QUALITY AND LONG-TERM RELATIONSHIPS.
//         ACTIVE THROUGHOUT SA AND SADC.
//         TAILORMADE SOLUTIONS TO SUIT OUR CLIENTS EVERY NEED AND FORWARD-THINKING SOLUTIONS TO ANY CHALLENGE.
//       </p>
//       <div style={servicesStyle}>
//         <div style={serviceStyle}>
//           <img src="/images/industrial/industrial3.jpeg" alt="Construction Management" style={serviceImageStyle} />
//           <h2 style={{ color: '#7295c7' }}>Construction Management</h2>
//           <p style={serviceTextStyle}>Proven hands-on Project Management, including acting as Principle Agent, Professional Team assembly, Feasibility studies, Project implementation, Project delivery and dispute resolution. Tenant Co-ordination and Spec negotiation.
//             General Building and contracting.</p>
//         </div>
//         <div style={serviceStyle}>
//           <img src="/images/turnkey/turnkey1.jpeg" alt="Turnkey Solutions" style={serviceImageStyle} />
//           <h2 style={{ color: '#7295c7' }}>Turnkey Solutions</h2>
//           <p style={serviceTextStyle}>We offer a total Turn-key solution, leveraging our extensive experience of contracting, a selection of preferred contractors, sub-contractors and suppliers to suit every project. 
//             Our Construction Managers have years of experience with some of the foremost contractors in SA, and are capable of delivering any project on time, in budget, and to the highest quality.</p>
//         </div>
//         <div style={serviceStyle}>
//           <img src="/images/leisure_gaming/leisure5.jpg" alt="Advisory Services" style={serviceImageStyle} />
//           <h2 style={{ color: '#7295c7' }}>Advisory Services</h2>
//           <p style={serviceTextStyle}>We regularly act in an Advisory capacity to Clients requiring guidance on development, contractual management and financial disbursement issues. 
//             The "Watching-brief" is becoming a recognised risk mitigation method for many looking to enter or expand their property development exposure.</p>
//         </div>
//       </div>

//       <div style={{ backgroundColor: '#333', color: '#fff', padding: '2rem', borderRadius: '5px', marginBottom: '2rem' }}>
//         <h1 style={{fontStyle: 'italic',}}>SERVICES</h1>
//         <p style={{fontStyle: 'italic',}}>THIS IS WHAT WE DO WELL.</p>
//         <div style={servicesStyle}>
//           <div style={serviceStyle}>
//             <img src="/images/construction.png" alt="Project Managers" style={serviceImageStyle} />
//             <h2 style={{ color: '#7295c7' }}>PROJECT MANAGERS</h2>
//             <p style={serviceTextStyle}>Acting as the Clients single point of contact with the Professional team, Contractors, Suppliers and end-users, (Tenants). We base our service on proven methods and best practise, and are proud of our 25 year history delivering successful projects.</p>
//           </div>
//           <div style={serviceStyle}>
//             <img src="/images/commercial/commercial2.jpg" alt="Contracting Solutions" style={serviceImageStyle} />
//             <h2 style={{ color: '#7295c7' }}>CONTRACTING SOLUTIONS</h2>
//             <p style={serviceTextStyle}>Certain situations require "Out the Box" solutions. Whether due to time constraints in procurement, design capability or risk mitigation, we can offer a full design & build solution to deliver your project.
//               Using established procurement systems, and a network of respected consultants and contractors, we can deliver any project within the agreed financial, timing and quality parameters.</p>
//           </div>
//           <p style={{fontStyle: 'italic',}}>Regardless of your project requirements, or field of involvement, we can offer you a viable, workable and cost-effective solution.</p>
//         </div>
//       </div>

//       <div style={portfolioStyle}>
//         <h1 style={{ color: '#475974', fontSize: '3rem'}}>Portfolio</h1>
//         <div style={categoriesStyle}>
//           {Object.keys(portfolioImages).map((category) => (
//             <button
//               key={category}
//               style={
//                 selectedCategory === category
//                   ? { ...buttonStyle, ...selectedButtonStyle }
//                   : buttonStyle
//               }
//               onMouseEnter={(e) => {
//                 if (selectedCategory !== category) {
//                   Object.assign(e.target.style, buttonHoverStyle);
//                 }
//               }}
//               onMouseLeave={(e) => {
//                 if (selectedCategory !== category) {
//                   Object.assign(e.target.style, buttonStyle);
//                 }
//               }}
//               onClick={() => handleCategoryClick(category)}
//             >
//               {category}
//             </button>
//           ))}
//         </div>
//         <div style={projectsStyle}>
//           {portfolioImages[selectedCategory].map((image, index) => (
//             <img key={index} src={image} alt={`${selectedCategory} Project`} style={projectImageStyle} />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;

import React, { useEffect, useState } from 'react';
import './Home.css'; // Import the CSS file

const portfolioImages = {
  Retail: [
    '/images/retail/retail2.jpg',
    '/images/retail/retail6.jpg',
    '/images/retail/retail1.jpg',
    '/images/retail/retail7.jpg',
  ],
  Hospitality: [
    '/images/leisure_gaming/leisure6.jpg',
    '/images/leisure_gaming/leisure7.jpg',
    '/images/leisure_gaming/leisure3.jpg',
    '/images/leisure_gaming/leisure5.jpg',
  ],
  Commercial: [
    '/images/commercial/commercial2.jpg',
    '/images/commercial/commercial7.jpg',
    '/images/commercial/commercial12.jpeg',
    '/images/commercial/commercial5.jpg'
  ],
  Industrial: [
    '/images/industrial/industrial1.jpeg',
    '/images/industrial/industrial5.jpeg',
    '/images/industrial/industrial8.jpeg',
    '/images/industrial/industrial3.jpeg',
  ],
  Healthcare: [
    '/images/healthcare/healthcare1.jpg',
    '/images/healthcare/healthcare2.jpg',
    '/images/healthcare/healthcare3.jpg',
    '/images/healthcare/healthcare4.jpg',
  ]
};

const Home = () => {
  const [selectedCategory, setSelectedCategory] = useState('Retail');

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    const styleSheet = document.styleSheets[0];
    const keyframes = `
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }, []);

  return (
    <div className="home">
      <div className="image-container">
        <img src="/images/cranes.jpg" alt="Construction Crane" className="image" />
      </div>
      <h1 className="home-title">What We Do</h1>
      <p className="home-description">
        PROVEN CONSTRUCTION PROJECT MANAGERS, DELIVERING A PROFESSIONAL SERVICE BASED ON TRUST, INTEGRITY, QUALITY AND LONG-TERM RELATIONSHIPS.
        ACTIVE THROUGHOUT SA AND SADC.
        TAILORMADE SOLUTIONS TO SUIT OUR CLIENTS EVERY NEED AND FORWARD-THINKING SOLUTIONS TO ANY CHALLENGE.
      </p>
      <div className="services">
        <div className="service">
          <img src="/images/industrial/industrial3.jpeg" alt="Construction Management" className="service-image" />
          <h2 className="service-title">Construction Management</h2>
          <p className="service-text">Proven hands-on Project Management, including acting as Principle Agent, Professional Team assembly, Feasibility studies, Project implementation, Project delivery and dispute resolution. Tenant Co-ordination and Spec negotiation.
            General Building and contracting.</p>
        </div>
        <div className="service">
          <img src="/images/turnkey/turnkey1.jpeg" alt="Turnkey Solutions" className="service-image" />
          <h2 className="service-title">Turnkey Solutions</h2>
          <p className="service-text">We offer a total Turn-key solution, leveraging our extensive experience of contracting, a selection of preferred contractors, sub-contractors and suppliers to suit every project. 
            Our Construction Managers have years of experience with some of the foremost contractors in SA, and are capable of delivering any project on time, in budget, and to the highest quality.</p>
        </div>
        <div className="service">
          <img src="/images/leisure_gaming/leisure5.jpg" alt="Advisory Services" className="service-image" />
          <h2 className="service-title">Advisory Services</h2>
          <p className="service-text">We regularly act in an Advisory capacity to Clients requiring guidance on development, contractual management and financial disbursement issues. 
            The "Watching-brief" is becoming a recognised risk mitigation method for many looking to enter or expand their property development exposure.</p>
        </div>
      </div>

      <div className="highlight-section">
        <h1 className="highlight-title">SERVICES</h1>
        <p className="highlight-subtitle">THIS IS WHAT WE DO WELL.</p>
        <div className="services">
          <div className="service">
            <img src="/images/construction.png" alt="Project Managers" className="service-image" />
            <h2 className="service-title">PROJECT MANAGERS</h2>
            <p className="service-text">Acting as the Clients single point of contact with the Professional team, Contractors, Suppliers and end-users, (Tenants). We base our service on proven methods and best practise, and are proud of our 25 year history delivering successful projects.</p>
          </div>
          <div className="service">
            <img src="/images/commercial/commercial2.jpg" alt="Contracting Solutions" className="service-image" />
            <h2 className="service-title">CONTRACTING SOLUTIONS</h2>
            <p className="service-text">Certain situations require "Out the Box" solutions. Whether due to time constraints in procurement, design capability or risk mitigation, we can offer a full design & build solution to deliver your project.
              Using established procurement systems, and a network of respected consultants and contractors, we can deliver any project within the agreed financial, timing and quality parameters.</p>
          </div>
          <p className="highlight-text">Regardless of your project requirements, or field of involvement, we can offer you a viable, workable and cost-effective solution.</p>
        </div>
      </div>

      <div className="portfolio">
        <h1 className="portfolio-title">Portfolio</h1>
        <div className="categories">
          {Object.keys(portfolioImages).map((category) => (
            <button
              key={category}
              className={`category-button ${selectedCategory === category ? 'selected' : ''}`}
              onMouseEnter={(e) => {
                if (selectedCategory !== category) {
                  e.target.classList.add('hover');
                }
              }}
              onMouseLeave={(e) => {
                if (selectedCategory !== category) {
                  e.target.classList.remove('hover');
                }
              }}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="projects">
          {portfolioImages[selectedCategory].map((image, index) => (
            <img key={index} src={image} alt={`${selectedCategory} Project`} className="project-image" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
