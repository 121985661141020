// import React from 'react';
// import { FaLinkedin } from 'react-icons/fa';
// import { MdEmail } from "react-icons/md";

// const footerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'space-between',
//   padding: '1rem 2rem',
//   backgroundColor: '#475974', // Dark blue background
//   color: '#fff', // White text
//   textAlign: 'center'
// };

// const contentStyle = {
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'flex-start',
//   width: '100%',
//   marginBottom: '1rem'
// };

// const textStyle = {
//   maxWidth: '25%',
//   textAlign: 'left',
// };

// const linksContainerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'flex-start',
//   marginRight: '5rem'
// };

// const linksStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'flex-start',
//   fontSize: '1.5rem',
//   marginTop: '0.5rem'
// };

// const linkStyle = {
//   color: '#fff',
//   textDecoration: 'none',
//   marginBottom: '0.5rem'
// };

// const Footer = () => {
//   return (
//     <footer style={footerStyle}>
//       <div style={contentStyle}>
//         <div style={textStyle}>
//           <h2 style={{fontStyle: 'italic',}}>About C-PRO PM</h2>
//           <p>
//             Proven construction Project Managers, delivering a professional service based on Trust, Integrity, Quality, and long-term relationships.
//             Active throughout SA and SADC.
//             Tailormade solutions to suit our clients every need and forward-thinking solutions to any challenge.
//           </p>
//         </div>
//         <div style={linksContainerStyle}>
//           <h2 style={{fontStyle: 'italic',}}>Socials</h2>
//           <div style={linksStyle}>
//             <a href="https://www.linkedin.com/company/c-pro-pm/" style={linkStyle} target="_blank" rel="noopener noreferrer">
//               <FaLinkedin /> LinkedIn
//             </a>
//             <a href="mailto:ed@c-pro.com" style={linkStyle} target="_blank" rel="noopener noreferrer">
//               <MdEmail /> Mail
//             </a>
//           </div>
//         </div>
//       </div>
//       <p style={{ margin: 0 }}>&copy; 2024 C-PRO Project Management (PTY) LTD. All Rights Reserved.</p>
//     </footer>
//   );
// };

// export default Footer;

import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-text">
          <h2 className="footer-title">About C-PRO PM</h2>
          <p>
            Proven construction Project Managers, delivering a professional service based on Trust, Integrity, Quality, and long-term relationships.
            Active throughout SA and SADC.
            Tailormade solutions to suit our clients every need and forward-thinking solutions to any challenge.
          </p>
        </div>
        <div className="footer-links-container">
          <h2 className="footer-title">Socials</h2>
          <div className="footer-links">
            <a href="https://www.linkedin.com/company/c-pro-pm/" className="footer-link" target="_blank" rel="noopener noreferrer">
              <FaLinkedin /> LinkedIn
            </a>
            <a href="mailto:ed@c-pro.com" className="footer-link" target="_blank" rel="noopener noreferrer">
              <MdEmail /> Mail
            </a>
          </div>
        </div>
      </div>
      <p className="footer-copyright">&copy; 2024 C-PRO Project Management (PTY) LTD. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
